
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { useI18n } from "vue-i18n";

interface Homework {
  createdAt: string;
  deadline: string;
  _id: string;
  submited: boolean;
  submission: { _id: string; createdAt: string };
}

export default defineComponent({
  name: "Student-Attendance-Statistic",
  props: {
    title: String,
    description: String,
    data: Object,
    height: Number,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();

    const homeworks = props.data as Homework[];
    const data = [0, 0, 0];

    homeworks.forEach((homework: Homework) => {
      if (!homework.submited) data[0]++;
      else {
        if (
          new Date(homework.deadline).getTime() <
          new Date(homework.submission.createdAt).getTime()
        )
          data[1]++;
        else data[2]++;
      }
    });

    const warningColor = getCSSVariableValue("--bs-info");
    const dangerColor = getCSSVariableValue("--bs-danger");
    const successColor = getCSSVariableValue("--bs-orange");

    const colors = [dangerColor, warningColor, successColor];

    const series = [
      {
        name: t("course.exercises"),
        data,
      },
    ];

    const options = {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      colors,
      plotOptions: {
        bar: {
          columnWidth: "45%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: [
          t("course.incompleted").split(" "),
          t("course.lateFinish").split(" "),
          t("course.completeInTime").split(" "),
        ],
        labels: {
          style: {
            colors,
            fontSize: "13px",
          },
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...data) + 1,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    };
    return {
      options,
      series,
    };
  },
});
