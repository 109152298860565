
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { useI18n } from "vue-i18n";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import SubmissionChart from "./submissionChart.vue";

interface Homework {
  id: string;
  subject: { _id: string; name: string };
  module: string;
  title: string;
  desc: string;
  submission: { files: string[]; createdAt: string; _id: string };
  marked: boolean;
  deadline: string;
  createdAt: string;
  updatedAt: string;
  spec?: boolean;
  submited: boolean;
  type: string;
}

export default defineComponent({
  name: "student-submission",
  components: { SubmissionChart },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const getFileName = (file: string): string => {
      const splited = file.split(/\\/);
      return splited[splited.length - 1];
    };
    let homeworks: Array<Homework> = [];

    ApiService.setHeader();
    ApiService.get(`/lms/homework/student/${route.params.id}`)
      .then(({ data }) => {
        data.forEach((exercise) => {
          const files: Array<string> = [];
          if (exercise.submission)
            exercise.submission.files.forEach((file) => {
              files.push(getFileName(file));
            });
          homeworks.push({
            id: exercise._id,
            subject: { name: exercise.subject.name, _id: exercise.subject._id },
            module: exercise.module,
            desc: exercise.desc,
            marked: exercise.marked,
            title: exercise.title,
            createdAt: exercise.createdAt,
            deadline: exercise.deadline,
            updatedAt: exercise.updatedAt,
            submission: exercise.submission,
            submited: !!exercise.submission,
            spec: exercise.spec,
            type: "exercise",
          });
        });

        ApiService.get(`/lms/quiz/student/${route.params.id}`)
          .then(({ data }) => {
            data.forEach((exercise) => {
              const files: Array<string> = [];
              if (exercise.submission)
                exercise.submission.files.forEach((file) => {
                  files.push(getFileName(file));
                });
              homeworks.push({
                id: exercise._id,
                subject: {
                  name: exercise.subject.name,
                  _id: exercise.subject._id,
                },
                module: exercise.module,
                desc: exercise.desc,
                marked: exercise.marked,
                title: exercise.title,
                createdAt: exercise.createdAt,
                deadline: exercise.deadline,
                updatedAt: exercise.updatedAt,
                submission: exercise.submission,
                submited: !!exercise.submission,
                spec: exercise.spec,
                type: "quiz",
              });
            });

            homeworks = homeworks.sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );

            filteredHomeworks.value = [...homeworks];

            updatedChart.value = true;

            completedHomeworks.value = 0;
            homeworks.forEach((homework: Homework) => {
              if (homework.submited) completedHomeworks.value++;
            });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));

    const filteredHomeworks = ref<Array<Homework>>([]);
    const search = ref<string>("");

    const getFileIconName = (file: string): string => {
      const type = file.split(".")[1];
      if (
        ["pdf", "png", "jpg", "ppt", "xls", "txt", "gif", "doc"].includes(type)
      )
        return type;
      return "file";
    };

    const getShortFileName = (file: string): string => {
      if (file.length > 20)
        return "..." + file.substring(file.length - 15, file.length);
      return file;
    };

    const searchHomeworks = () => {
      currentPage.value = 1;
      finishedCurrentPage.value = 1;
      manquantCurrentPage.value = 1;
      filteredHomeworks.value = homeworks.filter((homework) => {
        const toSearch = search.value.toLowerCase();
        return (
          homework.title.toLowerCase().includes(toSearch) ||
          homework.subject.name.toLowerCase().includes(toSearch)
        );
      });
    };

    const finishedHomeworks = computed(() =>
      filteredHomeworks.value.filter((homework) => homework.submited)
    );

    const manquantHomeworks = computed(() =>
      filteredHomeworks.value.filter((homework) => !homework.submited)
    );

    onMounted(() => {
      setCurrentPageTitle(t("classInfo.submissions"));
    });

    const updatedChart = ref<boolean>(false);
    const completedHomeworks = ref<number>(0);

    const perPage = 7;

    const currentPage = ref<number>(1);

    const totalPages = computed(() =>
      Math.ceil(filteredHomeworks.value.length / perPage)
    );

    const pageRecords = computed(() => {
      const startIndex = perPage * (currentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return filteredHomeworks.value.slice(startIndex - 1, endIndex);
    });

    const finishedCurrentPage = ref<number>(1);

    const finishedTotalPages = computed(() =>
      Math.ceil(finishedHomeworks.value.length / perPage)
    );

    const finishedPageRecords = computed(() => {
      const startIndex = perPage * (finishedCurrentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return finishedHomeworks.value.slice(startIndex - 1, endIndex);
    });

    const manquantCurrentPage = ref<number>(1);

    const manquantTotalPages = computed(() =>
      Math.ceil(manquantHomeworks.value.length / perPage)
    );

    const manquantPageRecords = computed(() => {
      const startIndex = perPage * (manquantCurrentPage.value - 1) + 1;
      const endIndex = startIndex + perPage - 1;
      return manquantHomeworks.value.slice(startIndex - 1, endIndex);
    });

    return {
      t,
      getFileIconName,
      getShortFileName,
      moment,

      search,
      searchHomeworks,
      homeworks,
      filteredHomeworks,
      finishedHomeworks,
      manquantHomeworks,
      updatedChart,
      completedHomeworks,
      perPage,
      currentPage,
      totalPages,
      pageRecords,
      finishedTotalPages,
      finishedPageRecords,
      manquantTotalPages,
      manquantPageRecords,
      finishedCurrentPage,
      manquantCurrentPage,
    };
  },
});
